import React from "react";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";

import { MapRE, SearchParamsEnum } from "@/game-eft/constants/constants.mjs";
import { lazyComponent } from "@/util/lazy-component.mjs";
import { useQuery } from "@/util/router-hooks.mjs";

const MAP_NAMES = Object.values(MapRE);

const MapComponent = lazyComponent(
  () => import("@/game-eft/components/InteractiveMap.jsx"),
);

export default function Maps() {
  const [map] = useQuery(SearchParamsEnum.MapName, MapRE.bigmap);
  const selected = MAP_NAMES.find((i) => i === map) ?? MapRE.bigmap;
  return (
    <div className="flex gap-2 column h-full w-full side-margin-auto">
      <MapComponent
        fallback={<LoadingSpinner />}
        props={{
          map: selected,
        }}
      />
    </div>
  );
}

export function meta() {
  return {
    title: ["eft:interactive-map.title", "Interactive Map"],
    description: [
      "eft:interactive-map.desc",
      "Navigate Escape from Tarkov like a pro with Blitz.gg's interactive maps, featuring real-time extraction points for every location. Plan your route, avoid danger zones, and make it out alive with the most up-to-date extraction data right at your fingertips.",
    ],
  };
}
